<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left">Data Verifikasi Lapangan</h4> 
            <select style="margin-left:15px;width:100px" @change="loadData" v-model="tahun" class="form-control-sm float-left form-control">
              <option v-for="year in years" :value="year">{{ year }}</option></select> 
              <!-- <a href="#/verlap/add" class="float-right btn btn-success form-control-sm">Tambah </a> -->
          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table class="table table-striped" id="datatable">
          <thead class="bg-info">
            <tr>
              <th>#</th>
              <th>Kode Barang</th>
              <th>Nama Barang</th>
              <th>Kota/Kabupaten</th>
              <th style="width:13%">Nama SKPD</th>
              <!-- <th style="width:13%">Nama OPD</th> -->
              <th>Lokasi</th>
              <th style="width:8%">No HAK</th>
              <th style="width:13%">Verifikasi Lapangan </th>
              <th>Aksi</th>
              <!-- <th>Hapus</th> -->
            </tr>
          </thead>
          <tbody>
            
          </tbody>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 

export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){

    this.loadData();
  },
  data: function() {
        return {
            products:[],
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    loadData: function() {
      if (this.tahun == null){
      
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
               responsive: true,
              "ajax":{
                     "url": process.env.VUE_APP_BASE_URL+"verlap",
                     "dataType": "json",
                     "type": "POST"
                     },
              "columns": [
                  { "data": "no",class:"text-center" },
                  { "data": "kode_barang" ,class:"text-center"}, 
                  { "data": "nama_barang" },
                  { "data": "kabupaten_kota" ,class:"text-center"},
                  { "data": "nama_skpd" },
                  { "data": "letak_lokasi" },
                  { "data": "no_hak",class:"text-center" },
                  { "data": "status_verlap",class:"text-center" },
                  { "data": "edit",class:"text-center" },
                  // { "data": null,
                  //   render: function(data, type, row, meta)
                  //     {
                  //         return "<a href=''>Detail</a>";
                  //     },
                  // },
              ]  

          });

          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        
          $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
               "pageLength": 50,
               responsive: true,
              "ajax":{
                     "url": process.env.VUE_APP_BASE_URL+"verlap",
                     "dataType": "json",
                     "type": "POST"
                     },
              "columns": [
                  { "data": "no",class:"text-center" },
                  { "data": "kode_barang" }, 
                  { "data": "nama_barang" },
                  { "data": "kabupaten_kota" ,class:"text-center"},
                  { "data": "nama_skpd" },
                  // { "data": "nama_instansi" },
                  { "data": "letak_lokasi" },
                  { "data": "no_hak",class:"text-center" },
                  { "data": "status_verlap",class:"text-center" },
                  { "data": "edit",class:"text-center" },
                  // { "data": "edit" },
              ]  
 
          });

          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      
    }
  }
}
</script>